import './unsupported-browser-message.css'

(function () {
    var ibContainer;
    var IS_UNSUPPORT_BROWSER_MESSAGE_MODAL_HIDDEN_KEY = 'IS_UNSUPPORT_BROWSER_MESSAGE_MODAL_HIDDEN'

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i = 0;i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function initEvent() {
        var body = document.getElementsByTagName('body')[0];
        addEvent('click', body, function (event) {
            if(event.target.className == 'unsupported-browser-footer-continue') {
                setCookie(IS_UNSUPPORT_BROWSER_MESSAGE_MODAL_HIDDEN_KEY, true, 365)
                ibContainer.style.display = 'none'
            }
        })
    }

    function isHiddenUnsupportBrowserModal() {
        return getCookie(IS_UNSUPPORT_BROWSER_MESSAGE_MODAL_HIDDEN_KEY) == 'true'
    }

    function isIE() {
        return navigator.appName === 'Microsoft Internet Explorer' || !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)
    }

    function docReady(fn) {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
            setTimeout(fn, 1)
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', fn)
        } else {
            window.attachEvent('onload', fn)
        }
    }

    function addEvent(evnt, elem, func) {
        if (elem.addEventListener)  // W3C DOM
            elem.addEventListener(evnt, func, false);
        else if (elem.attachEvent) { // IE DOM
            elem.attachEvent("on" + evnt, func);
        } else { // No much to do
            elem["on" + evnt] = func;
        }
    }

    function getModal() {
        var html = '';
        html += '    <div class="unsupported-browser-modal">';
        html += '        <div class="unsupported-browser-header">';
        html += '            <h1>Browser Not Supported</h1>';
        html += '            <p>';
        html += '                To get the best experience using the Optomany Order Portal we strongly recommend that you use the';
        html += '                latest version of one of the browsers below:';
        html += '            </p>';
        html += '        </div>';
        html += '        <div class="unsupported-browser-browsers">';
        html += '            <div class="unsupported-browser-browser-item">';
        html += '                <a href="http://www.google.com/chrome/browser/desktop/index.html" target="_blank">';
        html += '                    <div class="unsupported-browser-browser-icon unsupported-browser-browser-icon-chrome"></div>';
        html += '                    <div class="unsupported-browser-browser-name">';
        html += '                        Chrome';
        html += '                    </div>';
        html += '                </a>';
        html += '           </div>';
        html += '            <div class="unsupported-browser-browser-item">';
        html += '                <a href="http://www.firefox.com" target="_blank">';
        html += '                    <div class="unsupported-browser-browser-icon unsupported-browser-browser-icon-firefox"></div>';
        html += '                    <div class="unsupported-browser-browser-name">';
        html += '                        Firefox';
        html += '                    </div>';
        html += '                </a>';
        html += '            </div>';
        html += '            <div class="unsupported-browser-browser-item">';
        html += '                <a href="https://www.apple.com/safari" target="_blank">';
        html += '                    <div class="unsupported-browser-browser-icon unsupported-browser-browser-icon-safari"></div>';
        html += '                    <div class="unsupported-browser-browser-name">';
        html += '                        Safari';
        html += '                   </div>';
        html += '                </a>';
        html += '            </div>';
        html += '            <div class="unsupported-browser-browser-item">';
        html += '               <a href="http://www.opera.com" target="_blank">';
        html += '                    <div class="unsupported-browser-browser-icon unsupported-browser-browser-icon-opera"></div>';
        html += '                    <div class="unsupported-browser-browser-name">';
        html += '                        Opera';
        html += '                    </div>';
        html += '                </a>';
        html += '            </div>';
        html += '            <div class="unsupported-browser-browser-item">';
        html += '                <a href="https://www.microsoft.com/edge" target="_blank">';
        html += '                    <div class="unsupported-browser-browser-icon unsupported-browser-browser-icon-edge"></div>';
        html += '                    <div class="unsupported-browser-browser-name">';
        html += '                        Microsoft Edge';
        html += '                    </div>';
        html += '               </a>';
        html += '            </div>';
        html += '        </div>';
        html += '        <div>';
        html += '            If you choose to continue with this browser please be aware that functionality may be limited.';
        html += '       </div>';
        html += '       <div class="unsupported-browser-footer">';
        html += '            <div class="unsupported-browser-footer-continue" onclick="">';
        html += '                Continue';
        html += '            </div>';
        html += '        </div>';
        html += '    </div>';
        html += '    <div class="unsupported-browser-layout"></div>';
        return html;
    }


    ibContainer = document.createElement('div');
    ibContainer.setAttribute('class', 'unsupported-browser-container');
    ibContainer.innerHTML = getModal();

    docReady(() => {
        if (isIE() && !isHiddenUnsupportBrowserModal()) {
            initEvent()
            document.body.appendChild(ibContainer);
            ibContainer.style.display = 'block';
        }
    });
})();
